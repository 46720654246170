export interface UserDetail {
  id?: string;
  nome?: string;
  cognome?: string;
  email?: string;
  age?: string;
  createdAt?: string;
  lastModifyAt?: string;
  stato?: any;
  paese?: any;
  via?: any;
  cap?: any;
  provincia?: any;
  comune?: any;
  abbonamentoAttivo?: boolean;
  abbonamentoTp?: any;
  minorenne?: boolean;
  roles?: string[];
  phoneNumber?: string;
  phoneNumberConfirmed?: true;
  accessFailedCount?: 0;
}

export enum StatoEnum {
  none = 0,
  Attivo = 1,
  Disabilitato = 2,
  NonPagato = 3,
}

export enum AbboTp {
  none = 0,
  Gratuito = 1,
  Base = 2,
  Agonistico = 3,
}
